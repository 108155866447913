import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import moment from 'moment-timezone';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';
import * as constants from 'src/app/utils/constants';
import * as formatter from 'src/app/utils/formatter';
import * as exporter from 'src/app/utils/exporter';
import { DeviceViewModel } from 'src/app/models/device_model';
import { GroupViewModel } from 'src/app/models/group_model';
import { CompanyModel } from 'src/app/models/company_model';

@Component({
  selector: 'report-monthly-view',
  templateUrl: './report-monthly-view.component.html',
  styleUrls: ['./report-monthly-view.component.scss']
})
export class ReportMonthlyViewComponent extends BaseViewComponent implements OnInit {

  max = Math.max;

  @Input('userId')
  userId: string;

  @Input('user')
  user: UserViewModel;

  @Input('company')
  company: CompanyModel;

  @Input('companyName')
  companyName: string;

  @Input('mappings')
  mappings: Array<MappingViewModel>;

  @Input('selectedMapping')
  selectedMapping: MappingViewModel;

  @Input('selectedDate')
  selectedDate: moment.Moment;

  @Input('selectedTarget')
  selectedTarget: DeviceViewModel | GroupViewModel;

  @Input('shift')
  shift: DatumViewModel[];

  @Input('month')
  month: DatumViewModel[];

  @Input('monthBefore')
  monthBefore: DatumViewModel;

  @Input('daily')
  daily: DatumViewModel[];

  @Input('bannerText')
  bannerText: string;

  @Input('inProgressCounter')
  inProgressCounter: number;

  @Input('unitDetails')
  unitDetails: {
    volume: number,
    time: number,
    symbols: {
      volume: string,
      time: string,
      flowrate: string,
    }
  }

  @Output('mappingChangeClick')
  mappingChangeClick = new EventEmitter<MappingViewModel>();

  @Output('dateChangeClick')
  dateChangeClick = new EventEmitter<any>();

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  today = new Date();
  shiftChartData: Array<{name: string, series: Array<{name: string, value: number}>}> = [];
  shiftChartColorScheme = { domain: ['#31C3DF', '#FFD47A', '#DC189C'] };
  shiftAggregates: Array<{timing: string, value: DatumViewModel}>;
  dailyChartData: Array<{name: string, value: number}> = [];
  dailyChartColorScheme = { domain: [constants.getCssVariable('--primary-color')] };
  dailyChartCumulativeData: Array<{ name: string, series: Array<{name: string, value: number}>}>;
  dailyChartYAxisTicks: Array<number>;
  maxDailyCumulative: number;
  monthlyNocLimit: number;

  columnDefs = [
    { field: 'Time', valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY'), cellStyle: {'text-align': 'right'}},
    {
      field: 'Work Hours',
      valueFormatter: (params) => {
        if(formatter.dateIsSameOrAfter(params.data.Time) && params.value === 0){
          return "Nil";
        }
        return formatter.getWorkHourString(params.value)
      },
      cellStyle: {'text-align': 'right'}},
    {
      field: 'Net Volume',
      valueFormatter: (params) => {
        if(formatter.dateIsSameOrAfter(params.data.Time) && params.value === 0){
          return "Nil";
        }
        return formatter.numberWithCommas(params.value) + " " + this.unitDetails.symbols.volume
      },
      cellStyle: {'text-align': 'right'}
    },
    {
      field: 'Flow Rate',
      valueFormatter: (params) => {
        if(formatter.dateIsSameOrAfter(params.data.Time) && params.value === 0){
          return "Nil";
        }
        return formatter.numberWithCommas(params.value) + this.unitDetails.symbols.flowrate
      },
      cellStyle: {'text-align': 'right'}
    },
  ];

  dailyTableData = [];

  agGridContext = {
    timezone: null
  };

  @ViewChild('agGrid') agGrid: AgGridAngular;
  private api: GridApi;
  private columnApi: ColumnApi;

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.uiState !== this.ViewUiState.data) return;
    // Calculate the aggregates for each shift
    this.shiftAggregates = [];
    for(let s of this.shift) {
      const timing = moment(s.end_time).format('hh:mm a');
      const timingAggregate = this.shiftAggregates.find(a => a.timing === timing);
      timingAggregate ? timingAggregate.value.add(s): this.shiftAggregates.push({timing: timing, value: s.clone()});
    }
    // Show the chart for the shift-wise report
    this.shiftChartData = [];
    for(let s of this.shift) {
      const t = moment(s.end_time);
      const d = moment(s.end_time).startOf('day');
      // Skip all entries out of the current week
      if(t.isBefore(this.selectedDate.clone().startOf('week')) ||
         t.isAfter(this.selectedDate.clone().startOf('week').add(1, 'week'))
        ) continue;
      // Midnight reading is counted in previous day
      if(t.isSame(d)) d.subtract(1, 'day');
      const label = `${d.format('DD/MM')} to ${d.clone().add(1, 'day').format('DD/MM')}`
      const dataOfDay = this.shiftChartData.find(d => d.name === label);
      if(!dataOfDay) {
        this.shiftChartData.push({
          name: label,
          series: [{
            name: moment(s.end_time).format('hh:mm a'),
            value: s.net_volume
          }]
        });
      } else {
        dataOfDay.series.push({
          name: moment(s.end_time).format('hh:mm a'),
          value: s.net_volume
        });
      }
      this.monthlyNocLimit = this.selectedDate.daysInMonth() * this.selectedTarget.dailyNocLimit * this.unitDetails.volume;
    }

    // Calculate data for reading comparison chart
    this.dailyChartCumulativeData = [];
    let dailyCumulative = 0;
    let series = this.daily.map(s => {
      const timing = moment(s.start_time).format('MMM DD');
      dailyCumulative = dailyCumulative + s.net_volume*this.unitDetails.volume;
      return { name: timing, value: dailyCumulative};
    });
    this.maxDailyCumulative = dailyCumulative;
    series = [ {name: '', value: 0} , ...series]
    this.dailyChartCumulativeData.push({ name: this.selectedDate.format('YYYY MMM'), series: series});
    this.dailyChartYAxisTicks = [
      Math.round(dailyCumulative/4 * 0),
      Math.round(dailyCumulative/4 * 1),
      Math.round(dailyCumulative/4 * 2),
      Math.round(dailyCumulative/4 * 3),
      Math.round(dailyCumulative/4 * 4),
    ];
    // Show daily chart
    this.dailyChartData = this.daily.map(w => ({
      'name': `${moment(w.start_time).format('Do MMM')}`,
      'value': w.net_volume*this.unitDetails.volume,
    }));
    // Show the table for day-wise report
    this.dailyTableData = this.daily.filter(s => s.start_time < new Date()).map(s => ({
      'Time': s.start_time,
      'Work Hours': s.workhour,
      'Net Volume': Math.round(s.net_volume*this.unitDetails.volume*100)/100,
      'Flow Rate': Math.round(s.flowrate*this.unitDetails.volume/this.unitDetails.time*100)/100,
    }));
  }

  gridReady(params: GridReadyEvent) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.autoSizeAll(false);
    const grid = document.getElementsByClassName('ag-center-cols-container')[0]
    const gridWidth = (grid as HTMLElement).offsetWidth;
    const screenWidth = document.body.offsetWidth;
    if (screenWidth > gridWidth) {
      this.api.sizeColumnsToFit();
    }
  }

  autoSizeAll(skipHeader: boolean) {
    var allColumnIds = [];
    this.columnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  async onClickMappingChange(mapping: MappingViewModel) {
    this.mappingChangeClick.emit(mapping);
  }

  async onClickDateChange(date: any) {
    this.dateChangeClick.emit(date);
  }

  async onClickReset() {
    this.resetClick.emit(true);
  }

  onClickPrint() {
    // Open in new tab because window.print breaks flexbox styling
    // Flexbox breakpoints don't run! so fuck it, opening it in new tab
    window.open(`${window.location.pathname}/print${window.location.search}`, '_blank');
  }

  async onClickDownload() {
    const fileName = `Monthly Data Report ${this.selectedDate.format('MMM YYYY')}`;
    const sheetName = 'Daily Aggregates';
    exporter.exportData(this.company.name, fileName, sheetName, this.daily);
  }
}
